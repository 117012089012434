import Hero from "../../components/hero/hero.component";
import Intro from "../../components/intro/intro.component";
import NavigationCards from "../../components/navigation-cards/navigation-cards.component";
import ProjectCards from "../../components/project-cards/project-cards.component";
import Persons from "../../components/person/person-cards.component";

const Home = () => {
    return (
        <>
            <Hero />
            <Intro />
            <NavigationCards />
            <ProjectCards />
            <Persons />
        </>
    )
}

export default Home;