import ArticleComponent from "../../components/article/article.component";
import {NavLink} from "react-router-dom";

const Holunder = () => {
    return (
        <ArticleComponent caption="Gift im Holunder">
            <p>
                Kriterien für die Auswahl von Pflanzen in Anlagen der Holunderschule
            </p>
            <p>
                Immer wieder einmal kommen sie ins Gespräch - „giftige“ Pflanzen auf Spielplätzen,
                im Schulgelände und Kindergarten.<br />
                Welche Sträucher und Bäume sind für die Gestaltung von Freiräumen für Kinder
                geeignet, welche nicht? Welche Stauden, Frühblüher, Sommerblumen dürfen wir pflanzen,
                welche Wildkräuter am Heckenrand dulden?
            </p>
            <p>
                Bei unserer Antwort kommen wir mit Plus und Minus,
                mit „ja“ oder „nein“ nicht gut zurecht:<br />
                Ja! „Vor dem Holunder ziehe den Hut“ (alte Volksweisheit),
                Wurzel, Rinde, Blüten, Früchte: die reinste Apotheke!<br />
                Nein! Die Beeren, roh verzehrt, können zu Kopfweh und Übelkeit führen.<br />
                Ja! Die Schneeglöckchen sind uns willkommen als leuchtende Frühlingsboten.<br />
                Nein! Die Zwiebelchen, wie Schnittlauchzwiebeln genossen, verursachen Erbrechen und Durchfall.<br />
                Aber: Wer gräbt schon Schneeglöckchen aus, um Zwiebelchen zu essen?<br />
                Also: Mit zwei Spalten einer Liste für ja und nein, für Plus- und Minuspflanzen ist uns noch nicht geholfen.<br />
                Bei der Suche nach Kriterien für unsere Pflanzenauswahl geraten wir auf ein weites Feld,
                auf dem sich Landschaftsplaner, Biologen, Pädagogen, Vertreter von Versicherungen und Gemeindebehörden,
                Rechtsberater und Eltern tummeln und - Kinder!
            </p>
            <p>
                Und um die Kinder geht es hier. Es geht um ihre Sicherheit, natürlich,
                aber auch um ihr Recht auf Freude, Spiel, Naturerleben! Sie wollen doch im Schneeglöckchen nicht
                das Gift, sondern den Frühling sehen.<br />
                Und wir, die wir den Kindern eine schöne, naturnahe, lebensfrohe Umwelt schaffen
                wollen, wir sollten ihnen mit „Gift!“ nicht ihre kleine Welt vergällen, wo es nicht nötig ist.<br />
                Wohl aber sollten wir sie aufmerksam machen auf Gefahren in der sie umgebenden
                Lebewelt. Und so nachdrücklich - aber auch so selbstverständlich! - wie wir sagen:<br />
                „Erst bei Grün über die Straße gehen!“, so deutlich sollten wir sagen: „Fragt uns,
                bevor ihr etwas in den Mund stecken wollt!“
            </p>
            <p>
                Und seht mal, wie hier im Wald der Fingerhut leuchtet!<br />
                Märchenhaft schön - aber tödlich giftig! Lieber nicht in den Wald gehen? Oder ihn
                ausrotten, den Fingerhut? Und die Maiglöckchen, das Geißblatt, den Faulbaum,
                den Hartriegel, den Schneeball, den Bärenklau, die Brennesseln - ? <br />
                Pflanzen, denen unsere Kinder begegnen können, wo immer sie zum Spielen „nach draußen“
                gehen.<br />
                Und spätestens in der Grundschule sollten sie auch diesen Pflanzen begegnen,
                die für den Unterricht viel zu wichtig und interessant sind, als dass wir sie den Schülern vorenthalten könnten:
            </p>
            <p>
                Fingerhut - Digitalis: ein Heilmittel für kranke Herzen;<br />
                Faulbaum - Futter für die Raupen des Zitronenfalters, Honigblüten für unsere Bienen („Imkerbusch“!);<br />
                Schneeball - der Busch mit dem „Trick“: Scheinblüten (Viburnum opulus);<br />
                Hartriegel - kaum sind die Beeren schwarz, schon sind sie wieder weg: ein Lieblingsbusch unserer Vögel!<br />
                Geißblatt - duftende Blüten locken Nachtfalter an (engl.: Honeysuckle),<br />
                und dann der märchenhafte „Kletter-Name“: Jelängerjelieber!
            </p>
            <p>
                Weitere Beispiele - siehe <NavLink to="/literatur">„Wo die Büsche tanzen wollen..“</NavLink><br />
                In diesem Buch finden wir auch Hinweise auf „Erste Hilfe im Notfall“
                sowie eine Auflistung von Sträuchern der Kategorie „sehr stark giftig“,
                auf die wir im Holunderschul-Gelände verzichten können.
            </p>
            <p>
                Fazit:<br />
                Sicherheit, so meinen wir, ist für junge Menschen am ehesten dort gewährleistet,
                wo sie mögliche Gefahren erkennen und dann bewusst vermeiden können.<br />
                Das gilt für Pflanzen und Tiere (giftige Kreuzotter oder harmlose Ringelnatter?)
                genauso wie für die Spiel- und Bewegungsräume der Kinder.
                Sicher im Klettern werden sie nicht am DIN-genormten, TÜV-geprüften Kletterbogen, sondern zwischen Ästen und Zweigen,
                wo sie Gefahren erleben, begreifen, beachten und einzuschätzen lernen.
            </p>
            <p>
                Kontaktadressen im Notfall:<br />
                Giftinformationszentrum Nord / Te.: 0551-19240<br /> (HH, HB, Nds., SH)
            </p>
            <p>
                Gemeinde-Unfallversicherungsverband (GUV) Niedersachsen<br />
                Tel.: 0511-87070<br />
                Kontaktadressen anderer Bundesländer hier erfragen!
            </p>
        </ArticleComponent>
    )
}

export default Holunder;