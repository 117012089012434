import {Container, Row, Col, Button} from "react-bootstrap";
import CardComponent from "../card/card.component";
import Efeu from "../efeu/efeu.component";
import {ProjectData} from "../../data/project.data";
import {NavLink} from "react-router-dom";


const ProjectCards = () => {
    const PreviewProjects = ProjectData.filter(item => item.preview);
    return (
        <div className="pt-3 pb-5">
            <Container>
                <Efeu>
                    <Row>
                        <Col>
                            <h1 className="text-center">
                                Projekte der <span className="brand-secondary">Holunderschule</span>
                            </h1>
                            <h4 className="subtitle text-center mb-4">Ein Einblick in bisherige Projekte mit Vorher-/Nachher-Bildern</h4>
                        </Col>
                    </Row>
                </Efeu>
                <Row xs={1} md={3} className="g-4">
                    {PreviewProjects.map((item) => (
                            <Col key={item.id}>
                                <CardComponent key={item.id} imgPath={item.imgPath} title={item.caption} path={`/projekt/${item.id}`}>
                                    {item.street} <br/>
                                    {item.city}
                                </CardComponent>
                            </Col>
                        )
                    )}
                </Row>
                <p className="text-right pt-3">
                    <NavLink to="/projekte"><Button variant="secondary">Noch mehr Projekte</Button></NavLink>
                </p>

            </Container>
        </div>

    )
}

export default ProjectCards;