import ArticleComponent from "../../components/article/article.component";

import img1 from '../../assets/images/konzepte/img001.jpg';
import img2 from '../../assets/images/konzepte/fotogr1.jpg';
import img3 from '../../assets/images/konzepte/fotogr2.jpg';
import img4 from '../../assets/images/konzepte/fotogr3.jpg';
import img5 from '../../assets/images/konzepte/fotogr4.jpg';
import img6 from '../../assets/images/konzepte/fotogr5.jpg';
import img7 from '../../assets/images/konzepte/fotogr6.jpg';
import img8 from '../../assets/images/konzepte/fotogr7.jpg';
import img9 from '../../assets/images/konzepte/fotogr8.jpg';
import img10 from '../../assets/images/konzepte/fotogr9.jpg';
import stones from '../../assets/images/image9.gif';
import {NavLink} from "react-router-dom";

const Konzepte = () => {
    const renderStones = () => {
        return <p className="mb-5 mt-5">
            <img src={stones} alt="Steine" className="img-fluid"/>
        </p>
    }
    const renderImage = (img:string, alt:string) => {
        return <p>
            <img src={img} alt={alt} className="img-fluid"/>
        </p>
    }
    return (
        <ArticleComponent caption="Konzepte / Bilder / Pläne">
            <div className="text-center mt-5 mb-5">
                {renderImage(img1, 'Bild1')}
                {renderStones()}
                {renderImage(img2, 'Bild2')}
                {renderStones()}
                {renderImage(img3, 'Bild3')}
                {renderStones()}
                {renderImage(img4, 'Bild4')}
                {renderStones()}
                {renderImage(img5, 'Bild5')}
                {renderStones()}
                {renderImage(img6, 'Bild6')}
                {renderStones()}
                {renderImage(img7, 'Bild7')}
                {renderStones()}
                {renderImage(img8, 'Bild8')}
                {renderStones()}
                {renderImage(img9, 'Bild9')}
                {renderStones()}
                {renderImage(img10, 'Bild10')}
                {renderStones()}
                <p>
                    <NavLink to="/plaene">Pläne:</NavLink> Beispiele einer Schule und eines Kindergartens,
                    vor und nach der Umgestaltung
                </p>
            </div>
        </ArticleComponent>
    )
}

export default Konzepte;