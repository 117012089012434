import React from 'react';
import Header from "./components/header/header.component";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/home/home";
import Offers from "./routes/offers/offers";
import News from "./routes/news/news";
import Spielgelaende from "./routes/spielgelaende/spielgelaende";
import Holunder from "./routes/holunder/holunder";
import Konzepte from "./routes/konzepte/konzepte";
import Literatur from "./routes/literatur/literatur";
import Projekte from "./routes/projekte/projekte";
import Projekt from "./routes/projekte/projekt.details";

import './App.scss';
import Plaene from "./routes/plaene/plaene";
import Footer from "./components/footer/footer.component";

function App() {
  return (
    <div className="App">
        <Header />
        <Routes>
            <Route index path="/" element={<Home />}/>
            <Route path="/offers" element={<Offers />} />
            <Route path="/news" element={<News />} />
            <Route path="/spielgelaende" element={<Spielgelaende />} />
            <Route path="/giftimholunder" element={<Holunder />} />
            <Route path="/konzepte" element={<Konzepte />} />
            <Route path="/projekte" element={<Projekte />} />
            <Route path="/projekt/:projectId" element={<Projekt />} />
            <Route path="/literatur" element={<Literatur />} />
            <Route path="/plaene" element={<Plaene />} />
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
