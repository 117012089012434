import {Col, Container, Row} from "react-bootstrap";
import Efeu from "../efeu/efeu.component";
import {ReactNode} from "react";

import './article.styles.scss';

interface IArticle {
    caption: string,
    children: ReactNode
}
const ArticleComponent = (props: IArticle) => {
    return (
        <div className="pt-3 pb-3" >
            <Container>
                <Efeu>
                    <Row>
                        <Col>
                            <h1 className="text-center text-secondary pb-3">
                                {props.caption}
                            </h1>
                            <div className="text">
                                {props.children}
                            </div>
                        </Col>
                    </Row>
                </Efeu>
            </Container>
        </div>
    )
}

export default ArticleComponent;