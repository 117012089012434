export const PersonData = [
    {
        id: 100,
        company: 'Zentrale',
        name: 'Thomas Benjes',
        street: "Neuenlander Str. 29",
        city: "27356 Rotenburg/ Wümme",
        tel: '04261/962988',
        mobile: '0171 1664256',
        fax: '04261/962989',
        email: 'thomas.benjes@t-online.de',
        web: '',
    },
    {
        id: 200,
        company: '',
        name: 'Nicole Albers',
        street: 'Süderbusch 7',
        city: '21789 Wingst',
        tel: '04778/8009411',
        mobile: '0176 78543272',
        fax: '04778/819151',
        email: '',
        web: '',
    },
    {
        id: 300,
        company: '',
        name: 'Jürgen Basedow - Clark',
        street: 'Luckauer Ring 35',
        city: ' 31241 Ilsede',
        tel: '05172/961874',
        mobile: '',
        fax: '05172/961874',
        email: 'Basedow.Clark@gmail.com',
        web: '',
    },
    {
        id: 400,
        company: '',
        name: 'Heinrich Benjes ',
        street: 'Auf dem Brande 13 ',
        city: '27367 Hellwege ',
        tel: '04264/370356 ',
        mobile: '',
        fax: '04264/370357',
        email: 'hein.botterbloom@web.de',
        web: '',
    },
    {
        id: 500,
        company: '',
        name: 'Günter Buss',
        street: 'Hauptkanal links 93',
        city: '26871 Papenburg',
        tel: '04961/916847',
        mobile: '',
        fax: '04961/916835',
        email: 'guenter-buss@t-online.de',
        web: '',
    },
    {
        id: 600,
        company: '',
        name: 'Helmut Hahn',
        street: 'Hünningen 15',
        city: 'B - 4784 St. Vith',
        tel: '0032/80 226820',
        mobile: '',
        fax: '',
        email: 'helmut.hahn@belgacom.net',
        web: '',
    },
    {
        id: 700,
        company: '',
        name: 'Monika Stoll ',
        street: 'Schildweg 30',
        city: '37085 Göttingen',
        tel: '0551 / 770 28 98 ',
        mobile: '',
        fax: '',
        email: 'MonikaStoll.Holunderschule@web.de',
        web: '',
    },
    {
        id: 800,
        company: 'Büro für Grünplanung Landschaftsarchitekt ',
        name: 'Klaus Wappler ',
        street: 'Nienburger Straße 18 ',
        city: '27333 Schweringen',
        tel: '04257 / 9840940',
        mobile: '',
        fax: '04257 / 9840998',
        email: 'klaus.wappler@t-online.de',
        web: 'http://www.buerofuergruenplanung.de/',
    }
]