import Logo from "../../assets/images/logo.gif";
import './header.styles.scss';
import Navigation from "../navigation/navigation.component";
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                    <img src={Logo} alt="Holunderschule" className="logo" />
                </Link>
                <Navigation />
            </nav>
        </header>
    )
}

export default Header;