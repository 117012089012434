import ArticleComponent from "../../components/article/article.component";
import {Col, Row} from "react-bootstrap";
import CardComponent from "../../components/card/card.component";
import {ProjectData} from "../../data/project.data";

const Projekte = () => {
    return (
        <ArticleComponent caption="Projekte">
            <Row xs={1} md={3} className="g-4">
                {ProjectData.map((item) => (
                        <Col key={item.id}>
                            <CardComponent key={item.id} imgPath={item.imgPath} title={item.caption} path={`/projekt/${item.id}`} >
                                {item.street} <br/>
                                {item.city}
                            </CardComponent>
                        </Col>
                    )
                )}
            </Row>
        </ArticleComponent>
    )
}

export default Projekte;