import {Col, Container, Row} from "react-bootstrap";
import Efeu from "../efeu/efeu.component";

const Intro = () => {
    return (

            <Container>
                <Efeu>
                    <Row>
                        <Col>
                            <h1 className="text-center">
                                Willkommen in der <span className="brand-secondary">Holunderschule</span>
                            </h1>
                            <h4 className="subtitle text-center mb-4">Ihre Planer:innen für naturnahe Außenspiel- und Freiräume</h4>
                        </Col>
                    </Row>
                </Efeu>

            </Container>

    )
}

export default Intro;