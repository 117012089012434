import { Container, Row, Col } from "react-bootstrap";
import CardComponent from "../card/card.component";
const NavigationCards = () => {
    return (
        <div className="bg-dark-200 pt-5 pb-5">
            <Container>
                <Row xs={1} md={3} className="g-4">
                    <Col>
                        <CardComponent imgPath={require('../../assets/images/nav-cards/offer.jpg')} title="Angebote" path="offers">
                            <ul>
                                <li>Vorträge</li>
                                <li>Begehung</li>
                                <li>Workshop</li>
                                <li>Gestaltungskonzept</li>
                                <li>naturkundliche Begehungen</li>
                                <li>Ausführungsplanung</li>
                                <li>Aktionstage</li>
                            </ul>
                        </CardComponent>
                    </Col>
                    <Col>
                        <CardComponent imgPath={require('../../assets/images/nav-cards/infos.jpg')} title="Aktuelles/Infos/Temrine" path="news">
                            <ul>
                                <li>Berichte</li>
                                <li>Seminare</li>
                                <li>Vorträge</li>
                                <li>naturkundliche Begehungen</li>
                            </ul>
                        </CardComponent>
                    </Col>
                    <Col>
                        <CardComponent imgPath={require('../../assets/images/nav-cards/spielgelaende.jpg')} title="Außenspielgelände für Krippenkinder" path="spielgelaende">
                            Argumente für naturnahe Gestaltung von Krippengeländen
                        </CardComponent>
                    </Col>
                    <Col>
                        <CardComponent imgPath={require('../../assets/images/nav-cards/giftimholunder.jpg')} title="Gift im Holunder?" path="giftimholunder">
                            Kriterien für die Auswahl von Pflanzen in Anlagen der Holunderschule
                        </CardComponent>
                    </Col>
                    <Col>
                        <CardComponent imgPath={require('../../assets/images/nav-cards/konzepte.jpg')} title="Konzepte, Bilder, Pläne" path="konzepte">
                            Hier finden sie eine Konzeptbeschreibung in Worten und Bildern;
                            <br/>
                            dazu Beispiele eines Gestaltungskonzepts für Schule und Kindergarten.
                        </CardComponent>
                    </Col>
                    <Col>
                        <CardComponent imgPath={require('../../assets/images/nav-cards/projekte.jpg')} title="Projekte" path="projekte">
                            Hier finden sie einige Projekte mit Vorher- Nachher- Bildern
                        </CardComponent>
                    </Col>
                </Row>

            </Container>
        </div>

    )
}

export default NavigationCards;