import ArticleComponent from "../../components/article/article.component";
import {Accordion} from "react-bootstrap";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import './literatur.styles.scss';
import imgHolunderschule from '../../assets/images/books/image45.jpg';
import imgBuesche from '../../assets/images/books/image30.jpg';
import imgKuckuck from '../../assets/images/books/image49.jpg';
import imgGeschichten from '../../assets/images/books/image37.jpg';
import imgPlatt from '../../assets/images/books/image47.jpg';
import imgEigeneWorte from '../../assets/images/books/image48.jpg';

const Literatur = () => {
    return (
        <ArticleComponent caption="Literatur aus der Holunderschule">
            <Accordion>
                <AccordionItem eventKey="1">
                    <AccordionHeader className="align-items-start">
                        <img src={imgHolunderschule} alt="Holunderschule"/>
                        <div>
                            <h2 className="text-secondary">Holunderschule</h2>
                            <p className="text-bold">Das Buch
                                "Holunderschule"
                            </p>
                            <p>
                                Gestaltung von Spielplätzen, <br/>
                                Schulhöfen und Kindergärten
                            </p>
                            <p>
                                Lebensfroh, einfach, Kinderfreundlich
                            </p>
                            <p>
                                100 Seiten
                            </p>
                            <p>
                                Preis: 10,00 € <br/>
                                (Versand: + 2,00 €)
                            </p>
                        </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <p>
                            Dies, liebe Eltern und Pädagogen,
                            soll keine wissenschaftliche Abhandlung über die Welt der Kinder sein,
                            sondern ein Spaziergang mitten hinein! Wir ersparen uns den Umweg
                            über Thesen und Tabellen und sind schon nach wenigen Schritten da,
                            wo nicht das Wissen die Welt, sondern die Welt das Wissen schafft.
                            Ich selbst habe mich da mein Leben lang herumgetrieben und
                            mit den Kindern gelernt, mich von der Welt beschenken zu lassen.
                            Was sie den Kindern gibt und was sie mir gegeben hat und immer wieder geben will,
                            ist nicht sortiert und nicht gebündelt, es sind Geschenke wie Schnirkelschnecken
                            und fliegende Blätter, sind Träume und Visionen.
                        </p>
                        <p>
                            Und richtige Schätze sind auch dabei.<br/>
                            Die habe ich gesammelt und hier nun in buntem Gemisch zusammengetragen:<br/>
                            Da gibt es Schmetterlingsträume und Kuckucksstuhl; Vogellieder, Skizzen
                            und Pflanzenlisten; da liegen Beobachtungen und Erkenntnisse neben
                            Gedankensplittern und Schaufeln und Schiebkarre neben Märchen und Gedichten.
                        </p>
                        <p>
                            Der Rote Faden?<br/>
                            Keine Angst, ihr werdet ihn finden.<br/>
                            Wenn nicht: Es kann auch ganz lehrreich und vergnüglich sein,
                            wie der Grashüpfer unserer Holunderschule zwischen den Blättern
                            hin und her zu hüpfen.
                        </p>
                        <p className="text-bold">
                            Buchbestellung bei:
                        </p>
                        <p>
                            Heinrich Benjes<br/>
                            Auf dem Brande 13<br/>
                            27367 Hellwege<br/>
                            Tel.: 04264 / 370356<br/>
                            Fax 04264 / 370357<br/>
                            E-Mail: hein.botterbloom@web.de
                        </p>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey="2">
                    <AccordionHeader className="align-items-start">
                        <img src={imgBuesche} alt="Holunderschule"/>
                        <div>
                            <h2 className="text-secondary">Wo die Büsche tanzen wollen</h2>
                            <p>
                                Wie Sträucher und Bäume <br/>
                                vom Schulhof und Kindergarten <br/>
                                in Spiel und Unterricht wachsen
                            </p>
                            <p>
                                Autoren: <br/>
                                Susanne Müller, <br/>
                                Heinrich Benjes, <br/>
                                Maike & Falko Dienert
                            </p>
                            <p>
                                324 Seiten <br/>
                                120 sw-Zeichnungen
                            </p>
                            <p>
                                Preis: 20,00 Euro + Versandkosten <br/>
                                Lieferung gegen Rechnung
                            </p>
                        </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <p>
                            Wo die Büsche tanzen wollen
                            Tanzen?<br/>
                            Ja, seht euch doch nur mal den Holunderzweig an, wie der wippt, wo die Blaumeise unter den Blättern hängt!
                            Und wie sie sich bewegt im Haselnussbusch, da könnte das Eichhörnchen der Tänzer sein.<br/>
                            Und dann kommt der Wind! Da geht es wie Wellen durch die Bäume und Büsche...<br/>
                            Wie die Sonne, der Regen, und wie das Gewitter in den Hainbuchen tanzt! Das glitzert und blinkert nur so durch den Tag.
                            Und dann lässt der Herbst die Blätter tanzen!<br/>
                            Und dann ruh'n sie sich aus unter Raureif und Schnee. Doch schon mit den Kätzchen geht das Spiel wieder los,
                            und mitten im Spiel - die Kinder!
                        </p>
                        <p>
                            Tanzen?
                            Ja, seht euch doch nur ihre Hände an, ihre Finger und Füße in den Zweigen,<br/>
                            die Augen unter den Weidenkätzchen, die Nase über der Heckenrose - da tanzt doch der ganze Schulhof mit!
                        </p>
                        <p>
                            Tanzt munter hinein in den Unterricht mit Liedern, Märchen und Geschichten,<br/>
                            mit Schlehensaft, Goldammer und Flötenschnitzen, mit nachhaltig nachwachsender Lebensfreude!
                        </p>
                        <p>
                            Wo Bäume und Büsche tanzen wollen...<br/>
                            Da nehmen wir, wo nötig, die Platten weg, den Asphalt und die Rabattenkante.<br/>
                            Da lassen wir, wo der Busch nur noch Bürste ist, frech-fröhlich die jungen Zweige wachsen,<br/>
                            die wippen,wenn eine Blaumeise sich drunter hängt!
                        </p>
                        <p>
                            Das Buch erscheint im Selbstverlag<br/>
                            Und ist erhältlich bei:
                        </p>
                        <p>
                            Susanne Müller, Bramscher Str.217, 49090 Osnabrück<br/>
                            Tel./Fax: 0541-61575,<br/>
                            muellersue@gmx.de
                        </p>
                        <p>
                            Heinrich Benjes, Auf dem Brande 13, 27367 Hellwege<br/>
                            Tel.: 04264-370356,<br/>
                            hein.botterbloom@web.de
                        </p>
                        <p>
                            Maike & Falko Dienert, Schäfer-Ast-Str. 38, 21449 Radbruch <br/>
                            Tel./Fax: 04178-787, <br/>
                            <a href="mailto:baumbuch@web.de">baumbuch@web.de</a>
                        </p>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey="3">
                    <AccordionHeader className="align-items-start">
                        <img src={imgKuckuck} alt="Kuckucksstuhl und Katzenkäse"/>
                        <div>
                            <h2 className="text-secondary">Kuckucksstuhl und Katzenkäse</h2>
                            <p>
                                Zauberhafte Schätze!
                            </p>
                            <p>
                                Autor: <br/>
                                Heinrich Benjes
                            </p>
                            <p>
                                87 Seiten <br/>
                                mit über 40 bunten Zeichnungen <br/>
                                Festeinband
                            </p>
                            <p>
                                Preis: 20,00 € <br/>
                                (Versand: + 2,00 €)
                            </p>
                        </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <p>
                            Jenseits aller Poltereien in der Weltgeschichte möchte Hein Botterbloom
                            in seinem neuen Buch mit Kindern eine Welt erkunden, die groß, geheimnisvoll und schön ist,
                            eine Welt, die uns beglückende Abenteuer und tausend Schätze schenken will.
                            Zauberhafte Schätze! Da wird der Stängel vom Löwenzahn zu einer Trompete,
                            da werden die Binsen zum Kuckucksstuhl und die Früchtchen der Malve zum Katzenkäse,
                            da ist ein Grashalm die ganze Welt.
                        </p>
                        <p>
                            „Und du musst nicht suchen, finden genügt.“
                        </p>
                        <p className="text-bold">
                            Buchbestellung bei:
                        </p>
                        <p>
                            Heinrich Benjes <br/>
                            Auf dem Brande 13 <br/>
                            27367 Hellwege <br/>
                            Tel.: 04264 / 370356 <br/>
                            Fax 04264 / 370357 <br/>
                            <a href="mailto:hein.botterbloom@web.de">hein.botterbloom@web.de</a>
                        </p>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey="4">
                    <AccordionHeader className="align-items-start">
                        <img src={imgGeschichten} alt="Geschichten aus der Bärenhöhle"/>
                        <div>
                            <h2 className="text-secondary">Geschichten aus der Bärenhöhle</h2>
                            <p>
                                Wirklich und wahrhaftig im
                                Hellweger Wald gewachsen
                            </p>
                            <p>
                                Autor: <br/>
                                Hein Botterbloom
                            </p>
                            <p>
                                72 Seiten <br/>
                                20 kolorierte Zeichnungen <br/>
                                Leinenband / Schutzumschlag
                            </p>
                            <p>
                                Preis: 18,50 € <br/>
                                (Versand: + 2,00 €)
                            </p>
                        </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <p>
                            Der Mäusebussard hat sie zuerst gesehen- die Bären!
                            Die Bären sind zurückgekommen, durch das Schlehengebüsch, am Rehnengraben entlang über die Glockenheide bis in den Hellweger Wald.
                        </p>
                        <p>
                            Unter den Buchen hält Papabär seine große Begrüßungsrede, und alle, alle lauschen: Der Kleiber, der Rehbock, der Dachs, die Dohlen, der Grünspecht. Sogar der Eichelhäher, der Schreihals, hört zu und hält seinen Schnabel …
                        </p>
                        <p>
                            „Hein Botterbloom“ (Hein Benjes) schaut zurückauf ein langes Erzähler-Leben, und er sagt: „Diese Geschichte ist, wirklich und wahrhaftig, im Hellweger Wald gewachsen. Gewachsen, lebendig geworden, von den Bären, von Dachs und Pirol zu mir her gestürmt und dann zu den Kindern gesprungen. Aber auch den Großen wird es gefallen, dass die Freunde der Bären aus rasenden Leuten wieder sinnige Menschen machen.
                        </p>
                        <p className="text-bold">
                            Buchbestellung:
                        </p>
                        <p>
                            Über den Buchhandel (ISBN 978-3-940576-78-1)
                        </p>
                        <p>
                            oder direkt vom Autor
                        </p>
                        <p>
                            Heinrich Benjes <br/>
                            Auf dem Brande 13 <br/>
                            27367 Hellwege <br/>
                            Tel.: 04264 / 370356 <br/>
                            Fax 04264 / 370357 <br/>
                            <a href="mailto:hein.botterbloom@web.de">hein.botterbloom@web.de</a>
                        </p>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey="5">
                    <AccordionHeader className="align-items-start">
                        <img src={imgPlatt} alt="HEIN BOTTERBLOOM SNACKT PLATT"/>
                        <div>
                            <h2 className="text-secondary">HEIN BOTTERBLOOM SNACKT PLATT</h2>
                            <p>
                                Preis: 10,00 € <br/>
                                Incl. MwSt.: 7% <br/>
                                (+ Versandkosten)
                            </p>
                        </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <p>
                            un he vertell, wat em in all de Johrn so bifallen is:
                            "He sä nix, un se sä nix. Un so geev een Woort dat anner."
                            Tja, dat's Platt. Is dat nich schöön?
                        </p>
                        <p>
                            Platt, dat is mi domaals in Hormssen un Süste (1946-1951)
                            dör de Ohrn, den Haals rünner bit in den Buuk gahn, un dor is dat bleven.
                            De Schoolkinner snacken Platt, un de Lüe up'n Hoff snacken Platt -
                            de grode Heini, Hatsolns Mudder, us Vadder mit Unkel Friedel
                            un - Tant Gesine! Bi Tant Gesine is Platt to'n Minschen worrn
                            sinnig, blied un frisch heruut.
                        </p>
                        <p>
                            Un twintig Johr later, as ik Schoolmester wöör,
                            heff ik dat ut'n Buuk dör't Muul wedder ruut laten, dat Platt.
                            Un heegt un pleegt - un vertellt!
                        </p>
                        <p className="text-bold">
                            Bestellung:
                        </p>
                        <p>
                            Heinrich Benjes <br/>
                            Auf dem Brande 13 <br/>
                            27367 Hellwege <br/>
                            Tel.: 04264 / 370356 <br/>
                            Fax 04264 / 370357 <br/>
                            <a href="mailto:hein.botterbloom@web.de">hein.botterbloom@web.de</a>
                        </p>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem eventKey="6">
                    <AccordionHeader className="align-items-start">
                        <img src={imgEigeneWorte} alt="MIT EIGENEN WORTEN - HEIN BENJES ERZÄHLT MÄRCHEN"/>
                        <div>
                            <h2 className="text-secondary">MIT EIGENEN WORTEN - HEIN BENJES ERZÄHLT MÄRCHEN</h2>
                            <p>
                                Preis: 10,00 € <br/>
                                Incl. MwSt.: 7% <br/>
                                (+ Versandkosten)
                            </p>
                        </div>

                    </AccordionHeader>
                    <AccordionBody>
                        <p>
                            Hein Botterbloom packt das gern" in einen Pott": Das Spiel der Kleinen mit Stöcken und Steinen,
                            die Binsen für den Kuckucksstuhl, das Lied von Hüklüth im Teufelsmoor und all die leisen Geschichten,
                            die hinter der Hecke, unter den Buchen, im Mullersand, am Bach, bei den Stichlingen und Sternen wachsen.
                            Und die Märchen? <br/>
                            Er legt das Märchenbuch zur Seite, vertraut dem Zauber der Welt, wie er leibt und lebt,
                            und erzählt, wie ihm der Schnabel gewachsen ist - unter anderem von "Jan Pannkoken" (Plattdeutsch),
                            "Jungfer Lene von Söndervand" (Norwegen), von "Zwei Riesen, Wim und Wum" (Hein),
                            vom Südsee-Märchen "Das Mädchen im Mond" und von "Igel" aus Hein Botterblooms Erzählung
                            "Böko Holt und die blaue Libelle".
                        </p>
                        <p className="text-bold">
                            Bestellung:
                        </p>
                        <p>
                            Heinrich Benjes <br/>
                            Auf dem Brande 13 <br/>
                            27367 Hellwege <br/>
                            Tel.: 04264 / 370356 <br/>
                            Fax 04264 / 370357 <br/>
                            <a href="mailto:hein.botterbloom@web.de">hein.botterbloom@web.de</a>
                        </p>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </ArticleComponent>
    )
}

export default Literatur;