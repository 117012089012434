import ArticleComponent from "../../components/article/article.component";
import img1 from "../../assets/images/plaene/plzeisc2.jpg";
import img2 from "../../assets/images/plaene/plzeisc1.jpg";
import img3 from "../../assets/images/plaene/plzeki1.jpg";
import img4 from "../../assets/images/plaene/plzeiki2.jpg";
import stones from "../../assets/images/image9.gif";
import {Row, Col} from "react-bootstrap";

const Plaene = () => {
    const renderStones = () => {
        return <p className="mb-5 mt-5">
            <img src={stones} alt="Steine" className="img-fluid"/>
        </p>
    }
    const renderImage = (img:string, alt:string) => {
        return <p>
            <img src={img} alt={alt} className="img-fluid"/>
        </p>
    }
    return (
        <ArticleComponent caption="Pläne - vorher | nachher">
            <div className="text-center mt-5 mb-5">
                <Row>
                    <Col>
                        <h2>Schulgelände X</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <section>
                            <h2>vor der Umgestaltung</h2>
                            {renderImage(img1, 'Bild1')}
                            <p>
                                ..kantiger Schulhof, grantige Kinder - <br/>
                                jeder Eindruck will Ausdruck werden!
                            </p>
                            <p>
                                Ein Hartplatz für saubere Füße,<br/>
                                eine Grünfläche für den Rasenmäher,<br/>
                                eine Hecke für den jährlichen Bürstenschnitt,<br/>
                                eine Zufahrt für die Feuerwehr...
                            </p>
                        </section>
                    </Col>
                    <Col md={6}>
                        <section>
                            <h2>nach der Umgestaltung</h2>
                            {renderImage(img2, 'Bild2')}
                            <p>
                                Bewegt und lebensfroh- <br/>
                                ein Lebensraum ist liebenswert geworden... <br/>
                                und das Gelände geht in gefälliger Vielfalt auf Spiel und Ruhe, auf Lehren <br/>
                                und Lernen zu, wo vorher nur Langeweile im Wege stand.
                            </p>
                        </section>
                    </Col>
                </Row>

                {renderStones()}
                <Row>
                    <Col>
                        <h2>Kindergarten Bassum (b. Bremen)</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <section>
                            <h2>vor der Umgestaltung</h2>
                            {renderImage(img3, 'Bild3')}
                            <p>
                                Das Gelände ist kantig, <br/>
                                streng und unbewegt; <br/>
                                die riesige Rasenfläche überschaubar, <br/>
                                also langweilig! <br/>
                                Kein Wunder: <br/>
                                "Unsere Kinder wollen nicht raus.."
                            </p>
                        </section>
                    </Col>
                    <Col md={6}>
                        <section>
                            <h2>nach der Umgestaltung</h2>
                            {renderImage(img4, 'Bild4')}
                            <p>
                                Hier sind die Gebüschgruppen <br/>
                                mit heimischen Sträuchern das prägende Gestaltungselement: <br/>
                                Sie geben dem Gelände Bewegung und Lebendigkeit, <br/>
                                sie schaffen Räume- Schleichwege, Nischen und Höhlen; <br/>
                                und das belebende Verbindungselement ist ein breiter Wildkräuter-Saum.
                            </p>
                        </section>
                    </Col>
                </Row>

                {renderStones()}
            </div>
        </ArticleComponent>
    )
}

export default Plaene;