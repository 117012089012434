import {Container, Row, Col, Button, Card} from "react-bootstrap";
import Efeu from "../efeu/efeu.component";
import { PersonData } from "../../data/person.data";


const Persons = () => {
    return (
        <div className="bg-dark-200 pt-3 pb-5" id="kontakt">
            <Container>
                <Efeu>
                    <Row>
                        <Col>
                            <h1 className="text-center">
                                Die Planer:innen der <span className="brand-secondary">Holunderschule</span>
                            </h1>
                        </Col>
                    </Row>
                </Efeu>
                <Row xs={1} md={3} className="g-4">
                    {PersonData.map((item) => (
                            <Col key={item.id}>
                                <Card className="h-100">
                                    <Card.Body>
                                        <Card.Title>
                                            {item.company !== '' && <span>{item.company} <br/></span> }
                                            {item.name}
                                        </Card.Title>
                                        <Card.Text>
                                            {item.street !== '' && <span>{item.street} <br/></span> }
                                            {item.city !== '' && <span>{item.city} <br/></span> }
                                            {item.tel !== '' && <span>Tel.: {item.tel} <br/></span> }
                                            {item.mobile !== '' && <span>Mobil: {item.mobile} <br/></span> }
                                            {item.fax !== '' && <span>Fax: {item.fax} <br/></span> }
                                            {item.email !== '' && <span><a href={`mailto:${item.email}`}>{item.email}</a> <br/></span> }
                                            {item.web !== '' && <span><a href={item.web} target="_blank">{item.web}</a> <br/></span> }
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    )}
                </Row>

            </Container>
        </div>

    )
}

export default Persons;