import ArticleComponent from "../../components/article/article.component";

const News = () => {
    return (
        <ArticleComponent caption="Aktuelles / Infos / Termine">
            <h2>Holunderschule</h2>
            <p>Schulgelände und Unterricht</p>
            <p>Damit sie werden, was sie sind <br/>
                Der Schulhof als Entwicklungshelfer
            </p>
            <p> Wenn das Bildungsgut nur in die Köpfe zu bringen wäre – <br />
                wie gesundes Essen in die Töpfe, dann käme es nur auf die Zutaten an,<br />
                aber: Die Köpfe sind verschieden!<br />
                Was bei Julchen drin bleibt, läuft bei Fritzi aus den Ohren wieder heraus.<br />
                Und: Unter den Köpfen ist ja auch noch ein bisschen Mensch,<br />
                hier mehr Mops, da Gazelle; hier dickes Fell, da dünne Haut,<br />
                Streichelhände, Kletterhände, Matschhände ...
            </p>
            <p>
                Ja, das ist es, was als ganz wichtige Einsicht und Erkenntnis hinter,<br />
                nein: mitten in dem Konzept „Holunderschule“ steht:
            </p>
            <p>
                Die Kinder sind verschieden!<br /> Jedes Kind (jeder Mensch!) hat seine Königsseite!
            </p>
            <p>
                <span className="text-bold">„Königsseite“:</span><br />
                Malen – Singen – Erzählen – Rechnen – Klettern – Träumen – Bauen – Forschen ...<br />
                Wird diese Königsseite angesprochen (von Eltern, Lehrern, Erziehern entdeckt oder<br />
                auch zufällig „erwischt“), dann ist der Weg in Richtung Lebensfreude frei:
            </p>
            <p>
                <span className="text-bold"> Lebensfreude</span><br />
                als Grundmotivation ist die stärkste Triebkraft für gesunde Entwicklung<br />
                und für die Lust am Lernen.
            </p>
            <p>
                <span className="text-bold">Lernen!</span><br />
                Lebensfreude schließt die Kinder auf für Neugier – Aufmerksamkeit – Begeisterung <br />
                Beweglichkeit – Ausdauer – Durchhaltevermögen – Umsicht und Belastbarkeit<br />
                und macht sie empfänglich für Bestätigung (durch andere) und Anerkennung.
            </p>
            <p>
                <span className="text-bold">Das Schulgelände</span><br />
                sollte, erstens, von seiner Gestaltung her Lebensfreude wecken<br />
                und, zweitens, so vielgestaltig angelegt sein, dass es die „Königsseite“ der Kinder<br />
                ansprechen kann und, drittens, möglichst in ganzer Breite in den
            </p>
            <p>
                <span className="text-bold">Unterricht</span><br />
                einzubeziehen ist.
            </p>
            <p>&nbsp;</p>
            <h2>Termine</h2>
            <ul>
                <li>
                    <p>
                        Aktuell finden keine Termine statt
                    </p>
                </li>
            </ul>
        </ArticleComponent>

    )
}

export default News;