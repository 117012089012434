import heroImage from "../../assets/images/hero.jpg";
import './hero.styles.scss';

const Hero = () => {
    return (
        <div className="hero">
            <img src={heroImage} alt="" className="img-fluid" />
                <div className="cover">
                    Naturnahe Gestaltung von <br />Außenspiel- und Freiräumen
                </div>
        </div>
    )
}

export default Hero;