import img1 from "../assets/images/project-cards/pre7.jpg";
import img2 from "../assets/images/project-cards/after11.jpg";
import img3 from '../assets/images/project-cards/1.jpg';
import img4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04316-001.jpg';

import prev1_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSCF5060-001.jpg';
import post1_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC01949-001.jpg';
import prev2_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSCF5061-001.jpg';
import post2_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03510-001.jpg';
import prev3_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSCF5064-001.jpg';
import post3_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03512-001.jpg';
import prev4_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSCF5067-001.jpg';
import post4_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03514-001.jpg';
import prev5_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSCF5073-001.jpg';
import post5_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03505-001.jpg';
import prev6_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03509-001.jpg';
import post6_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03499-001.jpg';
import prev7_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03501-001.jpg';
import post7_1 from '../assets/images/projekte/ImagesWaldorfKitaDel/DSC03502-001.jpg';

import prev1_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00006-001.jpg';
import post1_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02441-001.jpg';
import prev2_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00007-001.jpg';
import post2_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02439-001.jpg';
import prev3_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00008-001.jpg';
import post3_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02442-001.jpg';
import prev4_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00009-001.jpg';
import post4_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02426-001.jpg';
import prev5_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00010-001.jpg';
import post5_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02425-001.jpg';
import prev6_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00011-001.jpg';
import post6_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02427-001.jpg';
import prev7_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00030-001.jpg';
import post7_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02447-001.jpg';
import prev8_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00018-001.jpg';
import post8_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02445-001.jpg';
import prev9_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC00020-001.jpg';
import post9_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02435-001.jpg';
import prev10_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02443-001.jpg';
import post10_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02450-001.jpg';
import prev11_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02430-001.jpg';
import post11_2 from '../assets/images/projekte/ImagesGSIhlpohl/DSC02444-001.jpg';

import prev1_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00049-2.jpg';
import post1_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00773-1.jpg';
import prev2_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00016-2.jpg';
import post2_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00780-2.jpg';
import prev3_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00034-2.jpg';
import post3_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00789-2.jpg';
import prev4_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00038-2.jpg';
import post4_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC02398-1.jpg';
import prev5_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00035-2.jpg';
import post5_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC02410-2.jpg';
import prev6_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00043-2.jpg';
import post6_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC03389-1.jpg';
import prev7_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00015-2.jpg';
import post7_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00821-1.jpg';
import prev8_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00029-2.jpg';
import post8_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00785-1.jpg';
import prev9_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00032-2.jpg';
import post9_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00800-1.jpg';
import prev10_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00033-2.jpg';
import post10_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00796-1.jpg';
import prev11_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC00037-2.jpg';
import post11_3 from '../assets/images/projekte/ImagesGSSchönebeck/DSC02399-2.jpg';

import prev1_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 018-001.jpg';
import post1_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04293-001.jpg';
import prev2_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 019-001.jpg';
import post2_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04312-001.jpg';
import prev3_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 020-001.jpg';
import post3_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04302-001.jpg';
import prev4_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 021-001.jpg';
import post4_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04299-001.jpg';
import prev5_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 022-001.jpg';
import post5_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04303-001.jpg';
import prev6_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 023-001.jpg';
import post6_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04301-001.jpg';
import prev7_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/Bild 062-001.jpg';
import post7_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04304-001.jpg';
import prev8_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04306-001.jpg';
import post8_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04311-001.jpg';
import prev9_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04295-001.jpg';
import post9_4 from '../assets/images/projekte/ImagesWabeKrippeLauenburg/DSC04296-001.jpg';

export const ProjectData = [
    {
        id: 1,
        caption: 'Waldorf- Kindertagesstätte',
        street: "Grundigstraße 12",
        city: "27753 Delmenhorst- Deichhorst",
        imgPath: img1,
        preview: true,
        images: [
            {prev: prev1_1, post: post1_1},
            {prev: prev2_1, post: post2_1},
            {prev: prev3_1, post: post3_1},
            {prev: prev4_1, post: post4_1},
            {prev: prev5_1, post: post5_1},
            {prev: prev6_1, post: post6_1},
            {prev: prev7_1, post: post7_1},
        ]
    },
    {
        id: 2,
        caption: 'Grundschule Ihlpohl',
        street: "Am Denkmal 9",
        city: "27721 Ritterhude",
        imgPath: img2,
        preview: true,
        images: [
            {prev: prev1_2, post: post1_2},
            {prev: prev2_2, post: post2_2},
            {prev: prev3_2, post: post3_2},
            {prev: prev4_2, post: post4_2},
            {prev: prev5_2, post: post5_2},
            {prev: prev6_2, post: post6_2},
            {prev: prev7_2, post: post7_2},
            {prev: prev8_2, post: post8_2},
            {prev: prev9_2, post: post9_2},
            {prev: prev10_2, post: post10_2},
            {prev: prev11_2, post: post11_2},
        ]
    },
    {
        id: 3,
        caption: 'Grundschule Schönebeck',
        street: "Herbartstr. 25",
        city: "28757 Bremen",
        imgPath: img3,
        preview: true,
        images: [
            {prev: prev1_3, post: post1_3},
            {prev: prev2_3, post: post2_3},
            {prev: prev3_3, post: post3_3},
            {prev: prev4_3, post: post4_3},
            {prev: prev5_3, post: post5_3},
            {prev: prev6_3, post: post6_3},
            {prev: prev7_3, post: post7_3},
            {prev: prev8_3, post: post8_3},
            {prev: prev9_3, post: post9_3},
            {prev: prev10_3, post: post10_3},
            {prev: prev11_3, post: post11_3},
        ]
    },
    {
        id: 4,
        caption: 'Wabe e.V. Krippe',
        street: "Birnenweg 2-4",
        city: "21481 Lauenburg",
        imgPath: img4,
        preview: false,
        images: [
            {prev: prev1_4, post: post1_4},
            {prev: prev2_4, post: post2_4},
            {prev: prev3_4, post: post3_4},
            {prev: prev4_4, post: post4_4},
            {prev: prev5_4, post: post5_4},
            {prev: prev6_4, post: post6_4},
            {prev: prev7_4, post: post7_4},
            {prev: prev8_4, post: post8_4},
            {prev: prev9_4, post: post9_4},
        ]
    }
]