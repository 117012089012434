import ArticleComponent from "../../components/article/article.component";
import imgGiftImHolunder from '../../assets/images/giftimholunder.jpg';
const Spielgelaende = () => {
    return (
        <ArticleComponent caption="Außenspielgelände für Krippenkinder">

                <p className="text-center mt-5">
                        <img className="margin-auto" src={imgGiftImHolunder} alt="Gift im Holunder" />
                </p>
            <p>&nbsp;</p>
            <h3>Argumente für eine naturnahe Gestaltung von Krippengelände</h3>
            <p>Welch große Bedeutung ein naturnah gestalteter Freiraum für die Entwicklung und das Verhalten von Kindern hat, können wir, die Mitglieder der Planungsgruppe HOLUNDERSCHULE, nach der Gestaltung von mehr als neunhundert Außenspielräumen inzwischen recht gut ermessen.<br /> Einmal abgesehen von der Bedeutung für die Ökologie im näheren Umfeld der Kinder (Wildkräuter, heimische Gehölze...) können wir die Auswirkungen eines naturnahen Geländes auf die Entwicklung und das Verhalten von Kindergarten- und Grundschulkindern („Krippe“ siehe unten!) wie folgt beschreiben:</p>
            <h3>Die Kinder werden ruhiger!</h3>
            <p>Das ist es, was Erzieherinnen, Lehrkräfte und Eltern immer wieder mit Erstaunen und großer Freude zur Kenntnis nehmen: Sobald das Außengelände modelliert und begrünt, durch Hecken, Gebüschgruppen, Hügel und Gräben begrenzt und strukturiert ist, sind Rangeleien und Geschrei wie weggeblasen.</p>
            <p>Erklärung: Die Kinder haben jetzt die Möglichkeit, sich in den Kleinstrukturen ihrer „Landschaft“ zu verteilen und sich in verschiedenen Spiel- und Bewegungsräumen in Kleingruppen zu finden.</p>
            <h3>Die Kinder finden sich selbst</h3>
            <p>Befreit aus dem ziellosen Gerangel und hilflosen Herumstehen auf einem nüchternen, überschaubaren Platten- oder Teppichrasenplatz, finden Kinder in einem naturnah gestalteten Gelände Gelegenheit, ihrer Eigenart entsprechend zu spielen und sich zu bewegen: Sie entdecken ihre Fähigkeiten (ich kann ein Zwergenhaus bauen!) und entwickeln Fertigkeiten (Dachdecken mit Grashalmen ...).</p>
            <p>Sie erleben: Ich kann etwas! Ich bin ein Baumeister, ein Künstler!</p>
            <p>Und dieses Gefühl des höchsten Glücks: ich kann, ich bin etwas, dieses Vertrauen in sich selbst ist doch die Grundlage einer gesunden Entwicklung und die Voraussetzung jeglichen Lernens! Je früher ein Kind diese Grundlage findet, desto glücklicher wird es sich entwickeln können. Kinder, denen diese Grundlage fehlt, die in ihren ersten Lebensjahren nie erleben konnten, was sie können, die dann gar nicht so recht wissen, was und wer sie sind- stehen als Jugendliche unsicher und unbeholfen vor den Anforderungen ihrer Welt und suchen in (teuren!) Hilfsmaßnahmen Richtwege und Unterstützung.</p>
            <p>Und darum: Schon in der Krippe, und gerade in der Krippe sollte Natur die Natur der Kinder bewegen!</p>
            <h3>Naturnahes Krippengelände</h3>
            <p>Die Fertigwelt der Großen brauchen die Kleinen nicht.</p>
            <p>Auf Plastikrutschen, lackierte Kletterstangen, Wackelhähnchen und Drehscheiben können wir verzichten. Die Welt der Kleinen ist klein: Blätter, Zweige, Grashalme, Pusteblume, Mullersand – das können Krippenkinder entdecken, anfassen, begreifen.</p>
            <p>Begreifen geht mit den Fingern los, Begriffe kommen später.</p>
            <p>Sprachforscher und Logopäden weisen immer wieder hin auf die ganz engeVerbindung von Sprachentwicklung und Fingerfertigkeit. Und darum geben wir den Kleinen neben dem geschorenen Rasen die bunte Vielfalt der wilden Kräuter (die wir einfach nur wachsen lassen müssen) und eine „Wilde Ecke“, in der Zweige, Klötze, Zapfen, Borkenstücke einladen zum Begreifen, Bauen und Gestalten.</p>
            <h3>Natur-nahes Gelände heißt aber auch: natur-schönes Gelände!</h3>
            <p>Die Farben der Blüten, die Formen der Blätter, die weich schwingenden Ränder von Hügeln und Gräben- das geht „unter die Haut“ und wächst sich aus zu einem Gespür für Ästhetik, das sich bei Kindern auf „Hartplätzen“ zwischen genormten Geräten, geraden Kanten und einförmigen Bodendeckern kaum entwickeln wird.</p>
            <p>Und auch dies sollten wir bei unsern Planungen bedenken:</p>
            <p>Ein einheitliches Muster für Krippengelände kann es nicht geben! Wie von Ort zu Ort, von Einrichtung zu Einrichtung die Gegebenheiten verschieden sind, so muss auch die Gestaltung des Geländes immer neu bedacht werden, damit der Außenraum seine Eigenart bewahren oder sein besonderes Gesicht bekommen kann.</p>
            <p><strong>Doch immer gilt: Das Maß sind die Kinder!</strong></p>
        </ArticleComponent>
    )
}

export default Spielgelaende;