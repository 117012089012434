import { ReactNode} from "react";
import './efeu.styles.scss';
interface IEfeuProps {
    children: ReactNode
}
const Efeu = ({ children }: IEfeuProps) => {
    return (
        <div className="intro efeu mb-5 mt-5">
            { children }
        </div>
    )
}

export default Efeu;