import Efeu from "../../components/efeu/efeu.component";
import {Container, Col, Row, Table} from "react-bootstrap";
import ArticleComponent from "../../components/article/article.component";

const Offers = () => {
    return (
        <ArticleComponent caption="Angebote">
            <Table striped>
                <thead>
                <tr>
                    <th>Leistungsart</th>
                    <th>Kurzbeschreibung</th>
                    <th>Vergütung in €</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><b>Vorträge</b> <br/>
                        (Heinrich Benjes)
                    </td>
                    <td>
                        a) "Was hat ein Grashüpfer mit Erziehung
                        zu tun?" <br/>
                        b) "Wie sie spielen, so werden sie!" <br/>
                        c) "Schlafapfel und Hexenbesen" <br/>
                        (heimische Sträucher u. Bäume in Spiel
                        und Unterricht)
                    </td>
                    <td>
                        z.Zt nicht im Angebot
                    </td>
                </tr>
                <tr>
                    <td className="text-bold">
                        Begehung
                    </td>
                    <td>
                        - Besichtigung des Spielplatzes <br/>
                        - Besprechung mit Eltern und Vertretern <br/>
                        (Lehrkräfte) der Einrichtung
                    </td>
                    <td>
                        72,00 (+) <br/>
                        (+ 19% MwSt.)
                    </td>
                </tr>
                <tr>
                    <td className="text-bold">
                        Workshop
                    </td>
                    <td>
                        - Diavortrag <br/>
                        - Einführung in die Planungsgrundsätze <br/>
                        - Skizzieren von Ideen und Vorschlägen <br/>
                        - Erarbeiten von Gestaltungsentwürfen
                        in Arbeitsgruppen <br/>
                        - Zusammenfassen der einzelnen
                        Gestaltungsvorschläge zu einem
                        Gesamtkonzept
                    </td>
                    <td>
                        390,00 (+) <br/>
                        (+ 19% MwSt.)
                    </td>
                </tr>
                <tr>
                    <td className="text-bold">
                        Gestaltungskonzept
                    </td>
                    <td>
                        - Ausarbeitung eines detaillierten
                        Planungsentwurfs (koloriert u. sw) <br/>
                        - Kostenschätzung
                    </td>
                    <td>
                        Auf Anfrage
                        (je nach Umfang) <br/>
                        Stundensatz 65,00
                        zuzgl. Nebenkosten <br/>
                        (+ 19% MwSt.)
                    </td>
                </tr>
                <tr>
                    <td className="text-bold">
                        Ausführungsplanung
                    </td>
                    <td>
                        - Absteckplan mit Pflanzrastern <br/>
                        - Pflanzlisten mit Anzahl und Qualität der verwendeten Pflanzen <br/>
                        - Detailzeichnung
                    </td>
                    <td>
                        Auf Anfrage
                        (je nach Umfang) <br/>
                        Stundensatz 65,00
                        zuzgl. Nebenkosten <br/>
                        (+ 19% MwSt.)
                    </td>
                </tr>
                <tr>
                    <td className="text-bold">
                        Aktionstage<br/>(Projekttage)
                    </td>
                    <td>
                        - Vorbereitung und Betreung von Aktionstagen (Elternabende)
                    </td>
                    <td>Auf Anfrage
                        (je nach Umfang)<br/>
                        Stundensatz 65,00
                        zuzgl. Nebenkosten<br/>
                        (+ 19% MwSt.)
                    </td>
                </tr>

                </tbody>
            </Table>
            <p className="text-center">
                (+) = Fahrtkosten: 0,40 €/km, 13,00€/Std.
            </p>
        </ArticleComponent>
    )
}

export default Offers;