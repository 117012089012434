import {ReactNode} from "react";
import { Card } from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import './card.styles.scss';

interface ICardProps {
    title: string,
    path: string,
    imgPath: string,
    children: ReactNode
}
const CardComponent = (props: ICardProps) => {
    const navigate = useNavigate();
    return (
        <Card className="h-100" onClick={() => navigate(props.path)}>
            <Card.Img variant="top" src={props.imgPath} />
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                    {props.children}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default CardComponent;