import {useEffect, useState} from "react";
import './navigation.styles.scss';
import {Link, useLocation} from "react-router-dom";
const Navigation = () => {
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        // "document.documentElement.scrollTo" is the magic for React Router Dom v6
        setOpen(false);
    }, [pathname]);
    return (
        <>
            <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation" onClick={() => setOpen(!open)}>
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`collapse navbar-collapse ${open && "show"}`} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                        <a className="nav-link" href="/#kontakt">Kontaktinfo</a>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/offers">Angebote</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/news">Aktuelles</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/spielgelaende">Außenspielgelände für Krippenkinder</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/giftimholunder">Gift im Holunder</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/konzepte">Konzepte,Bilder,Pläne</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/projekte">Projekte</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/literatur">Literatur</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Navigation;