import ArticleComponent from "../../components/article/article.component";
import {ProjectData} from "../../data/project.data";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Button, Container} from "react-bootstrap";

const ProjektDetails = () => {
    let { projectId } = useParams();
    if(projectId === undefined) {
        projectId = '1';
    }
    let id = parseInt(projectId);
    const Projects = ProjectData.filter(item => item.id == id);
    const Project = Projects[0];
    return (
        <>
            <Container className="mt-4">
                <NavLink to="/projekte"><Button variant="secondary">Zurück</Button></NavLink>
            </Container>
            <ArticleComponent caption={Project.caption}>
                <div className="text-center">
                    <h2>Vorher-/Nachher-Bilder</h2>
                    <p>
                        {Project.street} <br/>
                        {Project.city}
                    </p>
                    <div className="images">
                        {Project.images.map((item,key) => (
                            <div key={key}>
                                <img src={item.prev} />
                                    <img src={item.post} />
                            </div>
                        ))}
                    </div>
                </div>
            </ArticleComponent>
            <Container className="mb-4">
                <NavLink to="/projekte"><Button variant="secondary">Zurück</Button></NavLink>
            </Container>
        </>
    )
}

export default ProjektDetails;